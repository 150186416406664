import { Button, Container, FormField, Header, Input, Link, Select, SpaceBetween, Multiselect, ExpandableSection, TextFilter, Box, CollectionPreferences, Pagination, Table, StatusIndicator } from "@cloudscape-design/components"
import { CfnIPAMScope } from "aws-cdk-lib/aws-ec2";
import { FargateProfile } from "aws-cdk-lib/aws-eks";
import React from "react";
import { useParams } from "react-router-dom";

export default () => {

  const { orderId } = useParams(); 

  const [orderTitle, setOrderTitle] = React.useState("");
  const [order, setOrder] = React.useState({titles: [], orderDetail: {localisations: []}});
  const [orderUpdates, setOrderUpdates] = React.useState<any>({});
  const [orderUpdateComponents, setOrderUpdateComponents] = React.useState<any>([]);

  const [selectedItems, setSelectedItems] = React.useState<any>([]);

  function getOrderFromDb(orderId: string) {
    let auth_key = "" 
      if (localStorage.getItem('auth-key') !== null) {
        auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
      }
      console.log(`##${auth_key}##`);
      return fetch(`https://hj68uu89a6.execute-api.eu-west-1.amazonaws.com/Prod/orders/${orderId}`, {
        method: 'GET', 
        headers: new Headers ({
          'Authorization': auth_key
        })
      })
      .then(res => res.json())
  }

  function getOrderUpdatesFromDb(orderId: string) {
    let auth_key = "" 
      if (localStorage.getItem('auth-key') !== null) {
        auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
      }
      console.log(`##${auth_key}##`);
      return fetch(`https://hj68uu89a6.execute-api.eu-west-1.amazonaws.com/Prod/orders/${orderId}/order-updates`, {
        method: 'GET', 
        headers: new Headers ({
          'Authorization': auth_key
        })
      })
      .then(res => {
        console.log(res.status);
        return res.json();
      })
  }
  
  function buildOrderUpdateComponents(orderUpdatesArr: any) {
    let localizations:any = [];
    
    console.log(`orderUpdates is a: ${typeof(orderUpdatesArr)}`);
    console.log(JSON.stringify(orderUpdatesArr));
    if (orderUpdatesArr) {
      if (Object.keys(orderUpdatesArr).includes('localization-updates')) {
        Object.keys(orderUpdatesArr["localization-updates"]).forEach((locale: any) => {
          // build inner components first, add them incrementally then push to main collection.

          let services:any = [];

          // service - expandable 
          Object.keys(orderUpdatesArr["localization-updates"][locale]).forEach((service: any) => {
            const serviceObj = orderUpdatesArr["localization-updates"][locale][service];
            
            const serviceHeader = <div><span>Service: {service}</span> { serviceObj.isComplete ? getSuccessIndicator() : getInprogressIndicator() }</div>
            
            const serviceSection = <ExpandableSection
              defaultExpanded
              header={serviceHeader}
            >
              <Table
                columnDefinitions={[
                  {
                    id: "message",
                    header: "Message",
                    cell: (item: any) => item.message || "-",
                    sortingField: "message"
                  },
                  {
                    id: "status",
                    header: "Status",
                    cell: (item: any) => item.taskState === 'complete' || item.taskState === 'received' ? getSuccessIndicator() : item.taskState === 'inProgress' ? getInprogressIndicator() : getErrorIndicator() || "-",
                    sortingField: "alt"
                  },
                  {
                    id: "eventTime",
                    header: "Event Time",
                    cell: (item: any) => item.eventTime || "-"
                  }
                ]}
                items={orderUpdatesArr["localization-updates"][locale][service].statusObjects} 
                loading={false}
                loadingText="Loading resources"
                sortingDisabled
                variant="embedded"
                empty={
                  <Box textAlign="center" color="inherit">
                    <b>No messages</b>
                    <Box
                      padding={{ bottom: "s" }}
                      variant="p"
                      color="inherit"
                    >
                      No status messages to display.
                    </Box>
                  </Box>
                }
              />
            </ExpandableSection>

            services.push(serviceSection);
          });

          const localizationSection = <Container>
            <SpaceBetween size="m">
              <h2>Locale: { locale }</h2>
              { services }        
            </SpaceBetween>
          </Container>

          localizations.push(localizationSection);
        });
      }
    }

    return localizations;
  }

  function getInprogressIndicator() {
    return <StatusIndicator type="in-progress">
      In progress
    </StatusIndicator>;
  }

  function getSuccessIndicator() {
    return <StatusIndicator>Success</StatusIndicator>;
  }

  function getErrorIndicator() {
    return <StatusIndicator type="error">Error</StatusIndicator>
  }

  function viewActiveOrders() {
    window.location.href = '/active-orders';
  }

  function viewIncomingOrders() {
    window.location.href = '/active-orders';
  }

  function onLoad() {
    const currentOrderId = JSON.parse(JSON.stringify(orderId));
    console.log("Current Order ID");
    console.log(currentOrderId);
    getOrderFromDb(currentOrderId).then(
      (result) => {
        console.log(result);
        setOrder(result);
        //localStorage.setItem('jobList', JSON.stringify(result));
      },
      (error) => {
        // handle errors.
        console.log(error);
        localStorage.removeItem('auth-key');
        window.location.href = '/';
      }
    )

    setInterval(
      (() => {getOrderUpdatesFromDb(currentOrderId).then(
        (result) => {
          console.log(result);
          console.log('Setting order updates!');
          setOrderUpdates(result);
          setOrderUpdateComponents(buildOrderUpdateComponents(result));
          console.log(orderUpdateComponents);
        },
        (error) => {
          // handle errors.
          console.log(error);
          localStorage.removeItem('auth-key');
        }
      )}), 2000
    )
  }

  React.useEffect(onLoad, []);

  return (
    <SpaceBetween size="m">
      <Header
        actions={
          <SpaceBetween direction="horizontal" size="s">
            <Button onClick={viewIncomingOrders}>View Incoming Orders</Button>
            <Button onClick={viewActiveOrders} variant="primary">
              View Active Orders
            </Button>
          </SpaceBetween>
        }
        info={<Link variant="info">Info</Link>}
      >
        Active Localization Order Updates
      </Header>
      
          {/* <ExpandableSection
            defaultExpanded
            header={`Locale: ${'ARB'}`}
          >
            <Table
                  columnDefinitions={[
                    {
                      id: "message",
                      header: "Variable name",
                      cell: item => item.message || "-",
                      sortingField: "message"
                    },
                    {
                      id: "status",
                      header: "Status",
                      cell: item => item.status || "-",
                      sortingField: "alt"
                    },
                    {
                      id: "eventTime",
                      header: "Event Time",
                      cell: item => item.eventTime || "-"
                    }
                  ]}
                  items={[
                    { "message": "message", "status": "in progress", "eventTime": "2022-09-03 12:00:00"},
                    { "message": "message", "status": "in progress", "eventTime": "2022-09-03 12:00:00"},
                    { "message": "message", "status": "in progress", "eventTime": "2022-09-03 12:00:00"},
                    { "message": "message", "status": "in progress", "eventTime": "2022-09-03 12:00:00"},
                    { "message": "message", "status": "in progress", "eventTime": "2022-09-03 12:00:00"},
                    { "message": "message", "status": "in progress", "eventTime": "2022-09-03 12:00:00"},
                  ]} 
                  loading={false}
                  loadingText="Loading resources"
                  sortingDisabled
                  variant="embedded"
                  empty={
                    <Box textAlign="center" color="inherit">
                      <b>No resources</b>
                      <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                      >
                        No resources to display.
                      </Box>
                      <Button>Create resource</Button>
                    </Box>
                  }
                />
          </ExpandableSection> */}
          { orderUpdateComponents }
    </SpaceBetween>
  );
}  