// import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// import ActiveJobs from './pages/ActiveJobs/active-jobs';
// import NewJob from './pages/NewJob/new-job';
// import ArchivedJobs from './pages/ArchivedJobs/archived-jobs';
// import SideNavigation from '@cloudscape-design/components/side-navigation';
// import Badge from '@cloudscape-design/components/badge';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <App></App>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
