import * as React from "react";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import { CollectionPreferences, Container, Link, SpaceBetween, StatusIndicator } from "@cloudscape-design/components";

// import incomingOrders from './archived-IncomingOrders.json';
import { useCollection } from "@cloudscape-design/collection-hooks";

export default () => {
  
  const [
    selectedItems,
    setSelectedItems
  ] = React.useState([{
      "title": "",
      "orderId": "",
      "imdbId": "",
      "eidrId": "",
      "countryIso": "",
      "locale": "",
      "localization_requester": "",
      "localization_content_type": "",
      "localization_lang_type": "",
      "localization_lang_turnaround_time": "",
      "localization_lang_studio": "",
      "types": ""
  }]);

  const [incomingOrders, setIncomingOrders] = React.useState([{
    "title": "Loading...",
    "orderId": "",
    "imdbId": "",
    "eidrId": "",
    "countryIso": "",
    "locale": "",
    "localization_requester": "",
    "localization_content_type": "",
    "localization_lang_type": "",
    "localization_lang_turnaround_time": "",
    "localization_lang_studio": "",
    "types": ""
}]);

  const createOrder = () => {
    // const navigate = useNavigate();
    // navigate("/new-Order", {replace: true});
    window.location.href = '/new-order';
  }

  const viewActiveOrders = () => {
    // const navigate = useNavigate();
    // navigate("/new-Order", {replace: true});
    window.location.href = '/active-orders';
  }

  const viewApprovedDeals = () => {
    // const navigate = useNavigate();
    // navigate("/new-Order", {replace: true});
    window.location.href = '/approved-deals';
  }

  function getIncomingOrdersFromDb() {
    let auth_key = "" 
      if (localStorage.getItem('auth-key') !== null) {
        auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
      }
      console.log(`##${auth_key}##`);
      return fetch('https://hj68uu89a6.execute-api.eu-west-1.amazonaws.com/Prod/orders?state=pending', {
        method: 'GET', 
        headers: new Headers ({
          'Authorization': auth_key
        })
      })
      .then(res => res.json())
  }

  React.useEffect(() => {
    setSelectedItems([]); // clear selected item list.

    // let localIncomingOrders = localStorage.getItem('incomingOrders');
    // let localIncomingOrdersObj = [];

    // if (localIncomingOrders) {
    //   localIncomingOrdersObj = JSON.parse(localIncomingOrders);
    // }

    // if (localIncomingOrdersObj.length > 0) {
    //   setIncomingOrders(localIncomingOrdersObj);
    // }
    // else {

    getIncomingOrdersFromDb().then(
      (result) => {
        console.log(result);
        setIncomingOrders(result);
        //localStorage.setItem('OrderList', JSON.stringify(result));
      },
      (error) => {
        // handle errors.
        console.log(error);
        localStorage.removeItem('auth-key');
      }
    )

    // }
  }, []);

  interface emptyParams {
    title: string,
    subtitle: string,
    action: any
  }

  function EmptyState(params: emptyParams) {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {params.title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {params.subtitle}
        </Box>
        {params.action}
      </Box>
    );
  }

  function linkClick() {}

  const [preferences, setPreferences] = React.useState({ pageSize: 10, visibleContent: ['OrderTitle', 'Language', 'requester', 'type'] });
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    incomingOrders,
    {
      filtering: {
        empty: (
          <EmptyState
            title="No Incoming Orders"
            subtitle="No Incoming Orders to display."
            action=""
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  return (
    <SpaceBetween size="m">
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="s">
              <Button onClick={viewApprovedDeals}>View Approved Deals</Button>
              <Button onClick={viewActiveOrders} variant="primary">View Active Orders</Button>
            </SpaceBetween>
          }
          info={<Link variant="info">Info</Link>}
        >
          Incoming Orders
        </Header>
      <Table
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        selectedItems={selectedItems}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${
              selectedItems.length === 1 ? "item" : "items"
            } selected`,
          itemSelectionLabel: ({ selectedItems }, item) => {
            const isItemSelected = selectedItems.filter(
              i => i.title === item.title
            ).length;
            return `${item.title} is ${
              isItemSelected ? "" : "not"
            } selected`;
          }
        }}
        columnDefinitions={[
          {
            id: "OrderTitle",
            header: "Title",
            cell: e => <Link href={`/submit-order/${e.orderId}`}>{e.title}</Link>,
            sortingField: "OrderTitle"
          },
          {
            id: "Language",
            header: "Language",
            cell: e => e.localization_lang_type,
            sortingField: "language"
          },
          { id: "requester", header: "Requester", cell: e => e.localization_requester },
          // {
          //   id: "description",
          //   header: "Description",
          //   cell: e => e.description
          // },
          {
            id: "type", header: "Type", cell: e => e.types
          }
        ]}
        items={items}
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="OrderTitle"
        visibleColumns={preferences.visibleContent}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box
              padding={{ bottom: "s" }}
              variant="p"
              color="inherit"
            >
              No resources to display.
            </Box>
            <Button>Create resource</Button>
          </Box>
        }
        filter={<TextFilter
          {...filterProps}
          // countText={getMatchesCountText(filteredItemsCount)}
          filteringAriaLabel="Filter instances"
        />}
        header={
          <Header
            counter={
              selectedItems.length
                ? `(${selectedItems.length}/${incomingOrders.length})`
                : `(${incomingOrders.length})`
            }
          >
            List of  Incoming Orders
          </Header>
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 10,
              visibleContent: [
                "variable",
                "value",
                "type",
                "description"
              ]
            }}
            pageSizePreference={{
              title: "Select page size",
              options: [
                { value: 10, label: "10 resources" },
                { value: 20, label: "20 resources" }
              ]
            }}
            visibleContentPreference={{
              title: "Select visible content",
              options: [
                {
                  label: "Main distribution properties",
                  options: [
                    {
                      id: "variable",
                      label: "Variable name",
                      editable: false
                    },
                    { id: "value", label: "Text value" },
                    { id: "type", label: "Type" },
                    {
                      id: "description",
                      label: "Description"
                    }
                  ]
                }
              ]
            }}
          />
        }
      />
    </SpaceBetween>
  );
}

function setSelectedItems(selectedItems: { name: string; alt: string; description: string; type: string; size: string; }[]): void {
  throw new Error("Function not implemented.");
}
