import { Button, Container, FormField, Header, Input, Link, Select, SpaceBetween, Multiselect } from "@cloudscape-design/components"
import { CfnIPAMScope } from "aws-cdk-lib/aws-ec2";
import { FargateProfile } from "aws-cdk-lib/aws-eks";
import React from "react";
import { useParams } from "react-router-dom";

export default () => {

  const { orderId } = useParams(); 

  const [availableAssets, setAvailableAssets] = React.useState([]);
  const [availableDeals, setAvailableDeals] = React.useState<any>([]);
  const [orderTitle, setOrderTitle] = React.useState("");
  const [order, setOrder] = React.useState({titles: [], orderDetail: {localisations: []}});
  const [selectedAssets, setSelectedAssets] = React.useState<any>([]);
  const [selectedDeal, setSelectedDeal] = React.useState<any>({});
  const [selectedMixTypes, setSelectedMixTypes] = React.useState<any>([{"label": "2.0", "value": "2.0"}, {"label": "5.1", "value": "5.1"}, {"label": "7.1", "value": "7.1"}]);
  const availableMixTypes: any = [{"label": "2.0", "value": "2.0"}, {"label": "5.1", "value": "5.1"}, {"label": "7.1", "value": "7.1"}, {"label": "ATMOS", "value": "ATMOS"}]
  const [selectedSLA, setSelectedSLA] = React.useState<any>({"label": "Standard", "value": "Standard"});
  const availableSLAs: any = [{"label": "Standard", "value": "Standard"}, {"label": "Standard with Approvals", "value": "Standard with Approvals"}, {"label": "Complex", "value": "Complex"}]
  const [selectedWorkflow, setSelectedWorkflow] = React.useState<any>({"label": "Localization", "value": "Localization"});
  const availableWorkflows: any = [{"label": "Localization", "value": "Localization"}]
  const [selectedLangAttrib, setSelectedLangAttrib] = React.useState<any>({"label": "Full", "value": "full"});
  const availableLangAttribs: any = [{"label": "Full", "value": "full"},{"label": "Forced", "value": "forced"},{"label": "SDH", "value": "sdh"}]

  function getAssetsFromVidispine() {
    let auth_key = "" 
      if (localStorage.getItem('auth-key') !== null) {
        auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
      }
      console.log(`##${auth_key}##`);
      return fetch('https://hj68uu89a6.execute-api.eu-west-1.amazonaws.com/Prod/assets', {
        method: 'GET', 
        headers: new Headers ({
          'Authorization': auth_key
        })
      })
      .then(res => {
        console.log(res.status);
        return res.json();
      })
  }

  function getDealsFromDb() {
    let auth_key = "" 
      if (localStorage.getItem('auth-key') !== null) {
        auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
      }
      console.log(`##${auth_key}##`);
      return fetch('https://hj68uu89a6.execute-api.eu-west-1.amazonaws.com/Prod/deals', {
        method: 'GET', 
        headers: new Headers ({
          'Authorization': auth_key
        })
      })
      .then(res => {
        console.log(res.status);
        return res.json();
      })
  }
  
  function getOrderTitles(orderObj: any) {
    let titles = []
    for (let i = 0; i < orderObj.titles.length; i++)
    {
      let title = orderObj.titles[i];

      titles.push(
          <Container>
          <SpaceBetween size="m">
            <FormField
              description="Name of the Title Associated with the Order"
              label="Title"
              >
              <Input
                  value={title.name}
                  placeholder="Order title description."
                  readOnly={true}
              />
            </FormField>
            <FormField
              description="This Title's EIDR ID"
              label="EIDR ID"
              >
              <Input
                  value={title.eidrId}
                  placeholder="EIDR ID"
                  readOnly={true}
              />
            </FormField>
            <FormField
              description="This Title's Country ISO"
              label="Country ISO"
              >
              <Input
                  value={title.countryIso}
                  placeholder="Country ISO"
                  readOnly={true}
              />
            </FormField>
            <FormField
              description="This Title's Locale"
              label="Locale"
              >
              <Input
                  value={title.locale}
                  placeholder="Locale"
                  readOnly={true}
              />
            </FormField>
            <FormField
              description="This Title's Content Type"
              label="Content Type"
              >
              <Input
                  value={title.contentType}
                  placeholder="Content Type"
                  readOnly={true}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      );
    }

    return titles;
  }

  function getOrderLocalizations(orderObj: any) {
    let localisations = []
    console.log('ORDER OBJ');
    console.log(orderObj);
    for (let i = 0; i < orderObj.orderDetail.localisations.length; i++)
    {
      let localisation = orderObj.orderDetail.localisations[i];

      let languages = []

      for (let j = 0; j < localisation.languages.length; j++) 
      {
        let language = localisation.languages[j];
        let service = "";

        for (let k = 0; k < language.service.length; k++) 
        {
          service += `${language.service[k]}, `;
        }

        service = service.substring(0, service.length - 2);

        languages.push(
            <FormField
              description="Languages"
              label="Languages"
              >
              <Input
                  value={`${language.locale} (${service})`}
                  placeholder="Languages"
                  readOnly={true}
              />
            </FormField>
        )
      }

      localisations.push(
          <Container>
          <SpaceBetween size="m">
            <FormField
              description="Localisation PO Number."
              label="Localisation PO Number"
              >
              <Input
                  value={localisation.poNumber}
                  placeholder="Localisation PO Number"
                  readOnly={true}
              />
            </FormField>
            {
              languages
            }
          </SpaceBetween>
        </Container>
      );
    }

    return localisations;
  }

  function getOrderFromDb(orderId: string) {
    let auth_key = "" 
      if (localStorage.getItem('auth-key') !== null) {
        auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
      }
      console.log(`##${auth_key}##`);
      return fetch(`https://hj68uu89a6.execute-api.eu-west-1.amazonaws.com/Prod/orders/${orderId}`, {
        method: 'GET', 
        headers: new Headers ({
          'Authorization': auth_key
        })
      })
      .then(res => res.json())
  }

  function submitOrder() {
    const payload = JSON.parse(JSON.stringify(order));
    
    payload.assets = [];

    selectedAssets.forEach((asset: any) => {
      payload.assets.push({
        "assetLocation": asset.value
      })
    });

    payload.dealId = selectedDeal.value;

    console.log(JSON.stringify(payload));

    fetch('https://hj68uu89a6.execute-api.eu-west-1.amazonaws.com/Prod/orders', {
      method: 'POST', 
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('auth-key')}`
      },
      body: JSON.stringify(payload)
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          window.location.href = '/active-orders';
        },
        (error) => {
          // handle errors.
          console.log(error);
          // localStorage.removeItem('auth-key');
        }
      )
    // window.location.href = '/new-job';
  }

  function viewActiveOrders() {
    // const navigate = useNavigate();
    // navigate("/new-job", {replace: true});
    window.location.href = '/active-orders';
  }

  function onLoad() {
    const currentOrderId = JSON.parse(JSON.stringify(orderId));
    console.log("Current Order ID");
    console.log(currentOrderId);
    getOrderFromDb(currentOrderId).then(
      (result) => {
        console.log(result);
        setOrder(result);
        //localStorage.setItem('jobList', JSON.stringify(result));
      },
      (error) => {
        // handle errors.
        console.log(error);
        // localStorage.removeItem('auth-key');
      }
    )

    let localAssetList = localStorage.getItem('assetList');
    let localAssetListObj = [];

    if (localAssetList) {
      localAssetListObj = JSON.parse(localAssetList);
    }

    if (localAssetListObj.length > 0) {
      setAvailableAssets(localAssetListObj);
    }
    else {
      getAssetsFromVidispine().then(
        (result) => {
          console.log(result);
          setAvailableAssets(result);
          localStorage.setItem('assetList', JSON.stringify(result));
        },
        (error) => {
          // handle errors.
          console.log(error);
          // localStorage.removeItem('auth-key');
        }
      )
    }

    let localDealsList = localStorage.getItem('dealList');
    let localDealsListObj = [];

    if (localDealsList) {
      localDealsListObj = JSON.parse(localDealsList);
    }

    if (localDealsListObj.length > 0) {
      setAvailableDeals(localDealsListObj);
    }
    else {
      getDealsFromDb().then(
        (result: any) => {
          console.log(result);

          let dealList: any = [];

          result.forEach((deal: any) => {
            dealList.push({
              "label": `${deal.title} (${deal.termStart} - ${deal.termEnd})`,
              "value": deal.dealId
            });
          });

          setAvailableDeals(dealList);
          localStorage.setItem('dealList', JSON.stringify(dealList));
        },
        (error) => {
          // handle errors.
          console.log(error);
          localStorage.removeItem('auth-key');
          window.location.href = '/';
        }
      )
    }
  }

  React.useEffect(onLoad, []);

  return (
    <SpaceBetween size="m">
      <Header
        actions={
          <SpaceBetween direction="horizontal" size="s">
            <Button onClick={viewActiveOrders}>View Active Orders</Button>
            <Button onClick={submitOrder} variant="primary">
              Submit Order
            </Button>
          </SpaceBetween>
        }
        info={<Link variant="info">Info</Link>}
      >
        Localization Order Details
      </Header>
      <Container>
          <SpaceBetween size="m">
            {/* <FormField
              description="Fabric Order Id"
              label="Order Id"
              >
              <Input
                  value={orderTitle}
                  placeholder="Order Id"
                  readOnly={true}
              />
            </FormField> */}
            <FormField
                description="Please choose a workflow."
                label="Workflow"
                >
                <Select
                        selectedOption={selectedWorkflow}
                        onChange={({ detail }) =>
                        setSelectedWorkflow(detail.selectedOption)
                        }
                        options={availableWorkflows}
                        selectedAriaLabel="Selected"
                />
            </FormField>
            <h3 className="awsui_label_14mhv_yshzd_180">Please select mix types:</h3>
            <Multiselect
              selectedOptions={selectedMixTypes}
              onChange={({ detail }) =>
                setSelectedMixTypes(detail.selectedOptions)
              }
              deselectAriaLabel={e => `Remove ${e.label}`}
              options={availableMixTypes}
              placeholder="Choose mix types"
              selectedAriaLabel="Selected"
            />
            <FormField
                description="Please choose an SLA."
                label="SLA"
                >
                <Select
                        selectedOption={selectedSLA}
                        onChange={({ detail }) =>
                        setSelectedSLA(detail.selectedOption)
                        }
                        options={availableSLAs}
                        selectedAriaLabel="Selected"
                />
            </FormField>
            
            <FormField
                description="Please choose a Language Attribute."
                label="Language Attribute"
                >
                <Select
                        selectedOption={selectedLangAttrib}
                        onChange={({ detail }) =>
                        setSelectedLangAttrib(detail.selectedOption)
                        }
                        options={availableLangAttribs}
                        selectedAriaLabel="Selected"
                />
            </FormField>
          </SpaceBetween>
      </Container>
      {
        getOrderTitles(order)
      }
      <Container>
          <SpaceBetween size="m">
            
            <FormField
                description="Please choose a rightsline deal to associate this order to."
                label="Deal to Associate"
                >
                <Select
                        selectedOption={selectedDeal}
                        onChange={({ detail }) =>
                        setSelectedDeal(detail.selectedOption)
                        }
                        options={availableDeals}
                        selectedAriaLabel="Selected"
                />
            </FormField>
            <h3 className="awsui_label_14mhv_yshzd_180">Please select available assets:</h3>
            <Multiselect
              selectedOptions={selectedAssets}
              onChange={({ detail }) =>
                setSelectedAssets(detail.selectedOptions)
              }
              deselectAriaLabel={e => `Remove ${e.label}`}
              options={availableAssets}
              placeholder="Choose options"
              selectedAriaLabel="Selected"
            />
          </SpaceBetween>
      </Container>
      {
        getOrderLocalizations(order)
      }
    </SpaceBetween>
  );
}


async function localLocalTitlesFromS3() {
  
}
  