import * as React from "react";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import { CollectionPreferences, Container, Link, SpaceBetween, StatusIndicator } from "@cloudscape-design/components";

// import incomingOrders from './archived-IncomingOrders.json';
import { useCollection } from "@cloudscape-design/collection-hooks";

export default () => {
  
  const [selectedItems, setSelectedItems] = React.useState<any>([]);

  const [incomingOrders, setIncomingOrders] = React.useState<any>([]);

  const createOrder = () => {
    // const navigate = useNavigate();
    // navigate("/new-Order", {replace: true});
    window.location.href = '/new-order';
  }

  const viewIncomingOrders = () => {
    // const navigate = useNavigate();
    // navigate("/new-Order", {replace: true});
    window.location.href = '/incoming-orders';
  }

  const viewApprovedDeals = () => {
    // const navigate = useNavigate();
    // navigate("/new-Order", {replace: true});
    window.location.href = '/approved-deals';
  }

  function getIncomingOrdersFromDb() {
    let auth_key = "" 
      if (localStorage.getItem('auth-key') !== null) {
        auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
      }
      console.log(`##${auth_key}##`);
      return fetch('https://hj68uu89a6.execute-api.eu-west-1.amazonaws.com/Prod/orders?state=active', {
        method: 'GET', 
        headers: new Headers ({
          'Authorization': auth_key
        })
      })
      .then(res => res.json())
  }

  React.useEffect(() => {
    setSelectedItems([]); // clear selected item list.

    // let localIncomingOrders = localStorage.getItem('incomingOrders');
    // let localIncomingOrdersObj = [];

    // if (localIncomingOrders) {
    //   localIncomingOrdersObj = JSON.parse(localIncomingOrders);
    // }

    // if (localIncomingOrdersObj.length > 0) {
    //   setIncomingOrders(localIncomingOrdersObj);
    // }
    // else {

    getIncomingOrdersFromDb().then(
      (result) => {
        console.log(result);

        let orders = [];

        // result.forEach((order: any) => {
        //   orders.push({
        //     "fabricOrderId: 
        //   });
        // });
        setIncomingOrders(result);
        //localStorage.setItem('OrderList', JSON.stringify(result));
      },
      (error) => {
        // handle errors.
        console.log(error);
        localStorage.removeItem('auth-key');
        window.location.href = '/';
      }
    )

    // }
  }, []);

  interface emptyParams {
    title: string,
    subtitle: string,
    action: any
  }

  function EmptyState(params: emptyParams) {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {params.title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {params.subtitle}
        </Box>
        {params.action}
      </Box>
    );
  }

  const [preferences, setPreferences] = React.useState({ pageSize: 10, visibleContent: ['OrderId', 'WorkflowId', 'TotalTitles', 'TotalAssets'] });
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    incomingOrders,
    {
      filtering: {
        empty: (
          <EmptyState
            title="No Active Orders"
            subtitle="No Active Orders to display."
            action=""
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  return (
    <SpaceBetween size="m">
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="s">
              <Button onClick={viewApprovedDeals}>View Approved Deals</Button>
              <Button onClick={viewIncomingOrders} variant="primary">View Incoming Orders</Button>
            </SpaceBetween>
          }
          info={<Link variant="info">Info</Link>}
        >
          Active Orders
        </Header>
      <Table
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        selectedItems={selectedItems}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${
              selectedItems.length === 1 ? "item" : "items"
            } selected`,
          itemSelectionLabel: ({ selectedItems }, item) => {
            const isItemSelected = selectedItems.filter(
              i => i.title === item.title
            ).length;
            return `${item.title} is ${
              isItemSelected ? "" : "not"
            } selected`;
          }
        }}
        columnDefinitions={[
          {
            id: "OrderId",
            header: "Order Id",
            cell: (e: any) => <Link href={`/active-orders/${e.orderDetail.orderId}`}>{ e.orderDetail.fabricOrderId }</Link>,
            sortingField: "OrderId"
          },
          {
            id: "WorkflowId",
            header: "Workflow",
            cell: e => e.orderDetail.workflowId,
            sortingField: "WorkflowId"
          },
          {
            id: "TotalTitles", header: "TotalTitles", cell: e => e.titles.length
          },
          { id: "TotalAssets", header: "Total Assets", cell: e => e.assets.length },
          // {
          //   id: "description",
          //   header: "Description",
          //   cell: e => e.description
          // },
          
        ]}
        items={items}
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="OrderTitle"
        visibleColumns={preferences.visibleContent}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No active orders</b>
            <Box
              padding={{ bottom: "s" }}
              variant="p"
              color="inherit"
            >
              No active orders to display.
            </Box>
          </Box>
        }
        filter={<TextFilter
          {...filterProps}
          // countText={getMatchesCountText(filteredItemsCount)}
          filteringAriaLabel="Filter instances"
        />}
        header={
          <Header
            counter={
              selectedItems.length
                ? `(${selectedItems.length}/${incomingOrders.length})`
                : `(${incomingOrders.length})`
            }
          >
            List of  Active Orders
          </Header>
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 10,
              visibleContent: [
                "variable",
                "value",
                "type",
                "description"
              ]
            }}
            pageSizePreference={{
              title: "Select page size",
              options: [
                { value: 10, label: "10 resources" },
                { value: 20, label: "20 resources" }
              ]
            }}
            visibleContentPreference={{
              title: "Select visible content",
              options: [
                {
                  label: "Main distribution properties",
                  options: [
                    {
                      id: "variable",
                      label: "Variable name",
                      editable: false
                    },
                    { id: "value", label: "Text value" },
                    { id: "type", label: "Type" },
                    {
                      id: "description",
                      label: "Description"
                    }
                  ]
                }
              ]
            }}
          />
        }
      />
    </SpaceBetween>
  );
}

function setSelectedItems(selectedItems: { name: string; alt: string; description: string; type: string; size: string; }[]): void {
  throw new Error("Function not implemented.");
}
